import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/search',
    component: () =>
      import(/* webpackChunkName: "search" */ '../views/search/Search.vue'),
    children: [
      {
        path: '/search/domestic',
        name: 'Domestic',
        component: () =>
          import(
            /* webpackChunkName: "search" */ '../views/search/Domestic.vue'
          ),
      },
      {
        path: '/search/oversea',
        name: 'Oversea',
        component: () =>
          import(
            /* webpackChunkName: "search" */ '../views/search/Oversea.vue'
          ),
      },
    ],
  },

  // 마이페이지
  {
    path: '/mypage',
    name: 'Mypage',
    component: () =>
      import(/* webpackChunkName: "mypage" */ '../views/mypage/Home.vue'),
  },
  {
    path: '/mypage/cash_history',
    name: 'CashHistory',
    component: () =>
      import(
        /* webpackChunkName: "mypage" */ '../views/mypage/CashHistory.vue'
      ),
  },
  {
    path: '/mypage/history',
    name: 'CouponHistory',
    component: () =>
      import(
        /* webpackChunkName: "mypage" */ '../views/mypage/CouponHistory.vue'
      ),
  },
  {
    path: '/mypage/request-complete/:code',
    name: 'RequestComplete',
    component: () =>
      import(
        /* webpackChunkName: "mypage" */ '../views/mypage/RequestComplete.vue'
      ),
  },
  {
    path: '/mypage/reservation',
    name: 'Reservation',
    component: () =>
      import(
        /* webpackChunkName: "mypage" */ '../views/mypage/Reservation.vue'
      ),
  },
  {
    path: '/mypage/reservation/:code',
    name: 'ReservationDetail',
    component: () =>
      import(
        /* webpackChunkName: "mypage" */ '../views/mypage/ReservationDetail.vue'
      ),
  },
  {
    path: '/mypage/giftcard',
    name: 'GiftCard',
    component: () =>
      import(/* webpackChunkName: "mypage" */ '../views/mypage/GiftCard.vue'),
  },
  {
    path: '/mypage/profile',
    name: 'Profile',
    component: () =>
      import(/* webpackChunkName: "mypage" */ '../views/mypage/Profile.vue'),
  },
  {
    path: '/mypage/setting',
    name: 'Setting',
    component: () =>
      import(/* webpackChunkName: "mypage" */ '../views/mypage/Setting.vue'),
  },
  {
    path: '/mypage/change-password',
    name: 'Password',
    component: () =>
      import(
        /* webpackChunkName: "mypage" */ '../views/mypage/ChangePassword.vue'
      ),
  },
  {
    path: '/opt-out',
    name: 'OptOut',
    component: () =>
      import(/* webpackChunkName: "shop" */ '../views/mypage/OptOut.vue'),
  },
  {
    path: '/shop',
    name: 'Shop',
    component: () =>
      import(/* webpackChunkName: "shop" */ '../views/shop/Shop.vue'),
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () =>
      import(/* webpackChunkName: "shop" */ '../views/shop/Cart.vue'),
  },
  {
    path: '/shop-payment',
    name: 'ShopPayment',
    component: () =>
      import(/* webpackChunkName: "shop" */ '../views/shop/ShopPayment.vue'),
  },

  {
    path: '/bookmarks',
    name: 'Bookmarks',
    component: () =>
      import(/* webpackChunkName: "mypage" */ '../views/mypage/Bookmarks.vue'),
  },
  {
    path: '/term/:idx',
    name: 'Terms',
    component: () =>
      import(
        /* webpackChunkName: "mypage" */ '../views/mypage/TermsDetail.vue'
      ),
  },

  // 고객센터
  {
    path: '/cs/inquiry/write',
    name: 'CsInquiryWrite',
    component: () =>
      import(/* webpackChunkName: "cs" */ '../views/cs/InquiryWrite.vue'),
  },
  {
    path: '/cs/inquiry/list',
    name: 'CsInquiryList',
    component: () =>
      import(/* webpackChunkName: "cs" */ '../views/cs/InquiryList.vue'),
  },
  {
    path: '/cs/faq',
    name: 'CsFaq',
    component: () => import(/* webpackChunkName: "cs" */ '../views/cs/Faq.vue'),
  },
  {
    path: '/cs/notice',
    name: 'CsNotice',
    component: () =>
      import(/* webpackChunkName: "cs" */ '../views/cs/Notice.vue'),
  },
  {
    path: '/cs/notice/:code',
    name: 'CsNoticeDetail',
    component: () =>
      import(/* webpackChunkName: "cs" */ '../views/cs/NoticeDetail.vue'),
  },
  {
    path: '/payment/:code',
    name: 'PaymentDetail',
    component: () =>
      import(/* webpackChunkName: "payment" */ '../views/Payment/Main.vue'),
  },

  // 로그인&회원가입&기타(헤더 없는 페이지)
  {
    path: '/signin',
    name: 'SignIn',
    component: () =>
      import(/* webpackChunkName: "sign" */ '../views/sign/SignIn.vue'),
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () =>
      import(/* webpackChunkName: "sign" */ '../views/sign/SignUp.vue'),
  },
  {
    path: '/find-info',
    name: 'FindInfo',
    component: () =>
      import(/* webpackChunkName: "sign" */ '../views/sign/FindInfo.vue'),
    redirect: '/find-id',
    children: [
      {
        path: '/find-id',
        name: 'FindId',
        component: () =>
          import(/* webpackChunkName: "sign" */ '../views/sign/FindId.vue'),
      },
      {
        path: '/find-id-result/:code',
        name: 'FindIdResult',
        component: () =>
          import(/* webpackChunkName: "sign" */ '../views/sign/FindIdResult.vue'),
      },
      {
        path: '/find-pw',
        name: 'FindPw',
        component: () =>
          import(/* webpackChunkName: "sign" */ '../views/sign/FindPw.vue'),
      },
    ]
  }, 
  {
    path: '/change-pw/:code',
    name: 'ChangePw',
    component: () =>
      import(/* webpackChunkName: "sign" */ '../views/sign/ChangePw.vue'),
  },
  {
    path: '/receipt/:code',
    name: 'PaymentReceipt',
    component: () =>
      import(/* webpackChunkName: "payment" */ '../views/Payment/Receipt.vue'),
  },

  {
    path: '/gift',
    name: 'gift',
    component: () =>
      import(/* webpackChunkName: "payment" */ '../views/Payment/Gift.vue'),
  },
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name !== 'NavigationDuplicated') throw err;
  });
};

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  base: process.env.BASE_URL,
  routes,
});

export default router;
